
















































import { Component, Vue, Prop } from 'vue-property-decorator'
import { EntityResource } from '@/models/implementations/entities/EntityResource'
import { ImplementationResource } from '@/models/implementations/ImplementationResource'

import ProfileMenu from '@/components/ProfileMenu.vue'
import EntityPicker from './../../../implementations/partials/EntityPicker.vue'

@Component({
  components: {
    ProfileMenu,
    EntityPicker
  },
})
export default class ProjectNavigation extends Vue {
  @Prop()
  private readonly implementation!: ImplementationResource

  @Prop()
  private readonly entity!: EntityResource

  private menuOpen: boolean = false

  private get onNonImplementationRoute(): boolean {
    return this.$route.name === 'implementation-entity-team' || this.$route.name === 'implementation-entity-document'
  }

  private toggleMenu(): void {
    this.menuOpen = !this.menuOpen
  }

  private closeMenu(): void {
    this.menuOpen = false
  }

  private readNotifications(): void {
    // this.implementation.notifications.readAllNotifications()
  }

}

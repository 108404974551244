









import { Route } from 'vue-router'
import { stateModule } from '@/store'
import { Component, Vue, Watch } from 'vue-property-decorator'

import Loader from '@/components/loaders/Loader.vue'
import Navigation from './partials/Navigation.vue'

import { ImplementationResource } from '@/models/implementations/ImplementationResource'
import { ImplementationsService } from '@/services/implementations'
import { EntityResource } from '@/models/implementations/entities/EntityResource'

const implementationService = new ImplementationsService()

type watchedRoute = Route

@Component({
  components: {
    Loader,
    Navigation,
  },
})
export default class ImplementationDetail extends Vue {

  private entity: EntityResource | null = null
  private implementation: ImplementationResource | null = null

  private async beforeRouteEnter(to: Route, from: Route, next: any): Promise<void> {
    stateModule.setLoading(true)
    try {
      const { data: implementation } = await implementationService.getById(+to.params.implementation_id)
      const { data: entity } = await implementation.getEntityById(+to.params.entity_id)
      next((vm: ImplementationDetail) => {
        vm.implementation = implementation
        vm.entity = entity
      })
    } catch (e) {
      console.error(e)
      next({ name: 'implementations-overview' })
    } finally {
      stateModule.setLoading(false)
    }
  }

  @Watch('$route')
  private async onRouteChange(val: watchedRoute, oldVal: watchedRoute): Promise<void> {
    if (val.params.entity_id !== oldVal.params.entity_id && this.implementation) {
      stateModule.setLoading(true)

      const { data: entity } = await this.implementation.getEntityById(+val.params.entity_id)

      this.entity = entity
      stateModule.setLoading(false)
    }
  }

  private get loading(): boolean {
    return stateModule.loading
  }


}
